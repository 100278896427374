const Footer = () => {
  return (
    <footer className="dark">
      <section className="dark">
        <p>MeherDevs LLC | 2024</p>
      </section>
    </footer>
  );
};

export default Footer;
